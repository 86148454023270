
import {computed, defineComponent, ref} from "vue";
import {PinaFilterObjects, TeamSelect} from "@/core/composite/composite";
import {useUserGroupStore} from "@/store/usergroup.store";
import Entities from "@/components/base/common/Entities.vue";
import QuickAction from "@/components/base/action/QuickAction.vue";
import KtDatatable from "@/components/kt-datatable/KTDatatable.vue";
import BaseModal from "@/components/base/modal/BaseModal.vue";
import BaseForm from "@/components/base/form/BaseForm.vue";
import BaseSelect from "@/components/base/select/BaseSelect.vue";
import GroupService from "@/core/services/GroupService";

export default defineComponent({
  name: "Teams",
  components: {BaseSelect, BaseForm, BaseModal, KtDatatable, QuickAction, Entities},
  props: {
    spaceId: {}
  },
  setup(props) {
    const model = ref({teamId: ''})
    const submitting = ref(false);
    const filterObject = ref({groupId: props.spaceId})
    const userGroupStore = useUserGroupStore();
    const page = computed(() => userGroupStore.pageSecond)
    const headers = [
      {
        name: "Name",
        key: "name",
        sortable: true,
      },
      {
        name: "",
        key: "actions",
        actions: true,
      },
    ]
    return {
      model,
      submitting,
      ...TeamSelect(true),
      headers,
      page,
      ...PinaFilterObjects(userGroupStore, filterObject.value, ['userGroup'], '', true)
    }
  },
  methods: {
    onAdd() {
      this.model.teamId = '';
      const base = this.$refs.addTeamRef as typeof BaseModal;
      base.showBaseModal();
    },
    onSave() {
      this.submitting = true;
      GroupService.addGroup(this.spaceId, this.model.teamId).then(() => {
        this.paginationDataLoad()
        const base = this.$refs.addTeamRef as typeof BaseModal;
        base.hideBasemModal();
      }).finally(() => {
        this.submitting = false;
      })
    }
  }
})
